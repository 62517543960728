import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';

import { Container } from './FeaturesSlider.styles';

import boardListing from '../../../assets/images/board-listing.png';
import boardKanban from '../../../assets/images/board-kanban.png';
import boardMap from '../../../assets/images/board-map.png';
import { Colors } from '../../../styles/colors';

export const FeaturesSlider = () => {
  const [progress, setProgress] = useState(70);
  const [currentFeature, setCurrentFeature] = useState(0);
  const titles = ['Job Listing', 'Kanban View', 'Google Maps View'];
  const images = [boardListing, boardKanban, boardMap];
  const descriptions = [
    'Effortlessly manage and organize your job search journey! Track your applications seamlessly and keep everything neatly organized in one convenient place. Create an account now for a streamlined and efficient job search experience!',
    'Gain immediate insights into your journey, from applications to interviews and exciting job offers – all in one glance! Register now for a snapshot of your progress and elevate your job search experience!',
    'Discover your dream job locations with seamless Google Maps integration! Effortlessly visualize all your opportunities and gain instant insights into commute distances at a glance. Register now for an enhanced job search experience!',
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((previousProgress) => {
        if (previousProgress === 100) {
          setCurrentFeature(currentFeature === 2 ? 0 : currentFeature + 1);
          return 0;
        }
        return previousProgress + 1;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  return (
    <Container>
      <SliderWrapper>
        <SliderImage>
          <ImageExtended alt={'businessman'} src={images[currentFeature]} />
        </SliderImage>
        <SliderDescriptions>
          {titles.map((title, index) => {
            return (
              <Feature
                key={index}
                $isOpen={currentFeature === index}
                onClick={() => {
                  setCurrentFeature(index);
                  setProgress(0);
                }}
              >
                <FeatureProgressWrapper>
                  <BarContainer>
                    <Bar
                      style={{
                        height: `${currentFeature === index ? progress : 0}%`,
                      }}
                    />
                  </BarContainer>
                </FeatureProgressWrapper>
                <FeatureContent>
                  <FeatureTitle>{title}</FeatureTitle>
                  {currentFeature === index && <FeatureDescription>{descriptions[currentFeature]}</FeatureDescription>}
                </FeatureContent>
              </Feature>
            );
          })}
        </SliderDescriptions>
      </SliderWrapper>
    </Container>
  );
};

// React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SliderImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 320px;
`;

const SliderDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 320px;
  gap: 16px;
`;

const Feature = styled.div<{ $isOpen: boolean }>`
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: height 2s, height 4s;
  gap: 16px;
  &:hover {
    background-color: ${Colors.White};
  }
  ${(props) =>
    props.$isOpen &&
    css`
      background-color: ${Colors.White};
      cursor: default;
    `}
  cursor: pointer;
`;

const FeatureProgressWrapper = styled.div`
  width: 20px;
  position: relative;
  padding: 16px 0;
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
`;

const FeatureTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.Grey};
`;

const FeatureDescription = styled.div``;

const ImageExtended = styled.img``;

const BarContainer = styled.div`
  background-color: ${Colors.ContrastLightest};
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin-left: 16px;
  width: 5px;
  height: 100%;
  float: left;
  transition: height 2s, height 4s;
`;

const Bar = styled.div`
  background-color: ${Colors.ContrastDarkest};
  position: absolute;
  top: 0;
  width: 100%;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
`;
