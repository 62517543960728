import { Helmet } from 'react-helmet';
import { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/colors';
import organizingImage from '../../assets/vectors/organise-character.svg';
import onlineConnection from '../../assets/vectors/online_connection-character.svg';
import artificialIntelligence from '../../assets/vectors/artificial_intelligence-charater.svg';

import AIIcon from '../../assets/icons/ai.svg';
import JobSearchIcon from '../../assets/icons/job-search.svg';
import CVIcon from '../../assets/icons/cv.svg';
import organise1Icon from '../../assets/icons/organise1.svg';
import organise2Icon from '../../assets/icons/organise2.svg';
import organise3Icon from '../../assets/icons/organise3.svg';
import organise4Icon from '../../assets/icons/organise4.svg';
import collaborate1Icon from '../../assets/icons/collaborate1.svg';
import collaborate2Icon from '../../assets/icons/collaborate2.svg';
import collaborate3Icon from '../../assets/icons/collaborate3.svg';
import collaborate4Icon from '../../assets/icons/collaborate4.svg';
import organiseLottie from '../../assets/lotties/organise.json';
import monitorLottie from '../../assets/lotties/monitor.json';
import handshakeLottie from '../../assets/lotties/handshake.json';
import fingerprintScanLottie from '../../assets/lotties/fingerprint-scan.json';

import { H2, H4, Copy, H3 } from '../../components/atoms/Typography';
import { BulletList } from '../../components/atoms/BulletList';
import { Placeholder, Size } from '../../components/atoms/Placeholder/Placeholder';
import { PrimaryButtonLink, SecondaryButton } from '../../components/atoms/Button/Buttons';
import { HeroWithBackgroundImage } from '../../components/organisms/HeroWithBackgroundImage';
import { LayoutFullWidthMargin } from '../../components/atoms/Layout/Layout';
import { ChevronRightIcon, CreditCardIcon, CrownIcon } from '../../components/atoms/Icons';
import { CopyColoredSpan, CopyLineWrapper } from '../../components/atoms/Typography/Copy';
import { Pill } from '../../components/atoms/Pill';
import { media } from '../../styles/media';
import { Lottie } from '../../components/atoms/Lottie';
import { BulletListItem } from '../../components/atoms/BulletList/BulletList';
import { FeaturesGrid } from '../../components/organisms/FeaturesGrid';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { FeaturesSlider } from '../../components/organisms/FeaturesSlider';
import { FeaturesPanel } from '../../components/organisms/FeaturesPanel';
import { BackgroundColor } from '../../components/atoms/BackgroundColor';
import { Modal } from '../../components/atoms/Modal';
import { SmallLoader } from '../../components/atoms/Loader';
import { VideoPlayer } from '../../components/atoms/VideoPlayer';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import { ButtonsWrapper } from '../Board/View/View.styles';
import {
  ResumePreview,
  ResumesPreviewCentralWrapper,
  ResumesPreviewLeftWrapper,
  ResumesPreviewRightWrapper,
  ResumesPreviewWrapper,
} from '../../components/molecules/ResumePreview';
import { FunctionalIFrameComponent } from '../../components/atoms/FunctionalIFrameComponent';
import { stateMozard } from '../../states/stateMozard';
import { stateBeethoven } from '../../states/stateBeethoven';
import { stateChopin } from '../../states/stateChopin';
import { coverLetterStateMozard } from '../../states/coverletterMozard';
import { coverLetterBeethoven } from '../../states/coverletterBeethoven';
import { coverLetterChopin } from '../../states/coverletterChopin';
import { CoverLetterPreview } from '../../components/molecules/CoverLetterPreview';

const Home = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initialNumberOfParagraphs = coverLetterStateMozard.coverLetter.content.length;
  const initialJobTitle = 'Composer and Musician';
  const [counter, setCounter] = useState(0);
  const interval = useRef<ReturnType<typeof setInterval>>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let i = 1;
    interval.current = setInterval(() => {
      i = i + 1;
      setCounter(i);
    }, 500);
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, []);

  const stateMozardUpdated = { ...stateMozard };
  stateMozardUpdated.resume.basics.jobTitle = initialJobTitle.substring(0, (counter % initialJobTitle.length) + 1);
  const coverLetterStateMozardUpdated = JSON.parse(JSON.stringify(coverLetterStateMozard));
  coverLetterStateMozardUpdated.coverLetter.content = coverLetterStateMozard.coverLetter.content.slice(
    0,
    counter % initialNumberOfParagraphs
  );

  return (
    <>
      <Helmet title="Job Application Tracker" />
      <HeroWithBackgroundImage />
      <Spacer y={64} />
      <LayoutFullWidthMargin>
        <TableWrapper>
          <Wrapper>
            <H2>Get Started for free</H2>
            <Copy styleLevel={1} marginBottom={8}>
              Take control of your job application journey with JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, the
              ultimate solution for managing your Job Search.
            </Copy>
            <Copy styleLevel={3} marginBottom={32} isItalic={true}>
              <CopyLineWrapper>
                <CreditCardIcon size={1} />
                No credit card needed. Upgrade whenever you choose.
              </CopyLineWrapper>
            </Copy>
            <PrimaryButtonLink to="login" inline={true} size="large">
              Login / SignUp
            </PrimaryButtonLink>
          </Wrapper>
          <Wrapper>
            <H4>
              What is JobsBoard<span>.io</span>?
            </H4>
            <BulletList $hasBullets={false}>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="Organise" autoplay={true} animationData={monitorLottie} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      <strong>Organise</strong> - Keep track of the positions you have applied for, including the
                      company name, job title, application date, and status (e.g., submitted, interview, offer). It
                      helps in maintaining an organised record of job applications and monitoring the progress{' '}
                      <b>all in one place</b>.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="Collaborate" autoplay={true} animationData={handshakeLottie} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      <strong>Collaborate</strong> - Facilitate seamless collaboration with recruitment agencies or
                      recruiters by enabling them to generate job applications directly on your job board. Harness their
                      expertise to streamline your job search process as they assist in creating essential{' '}
                      <b>events, tasks, and notes,</b> ensuring effective communication throughout the hiring journey.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="Consolidate" autoplay={true} animationData={organiseLottie} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      <strong>Consolidate</strong> - Centralize all your crucial documents, including your impressive
                      CVs and compelling Cover Letters, in a single, streamlined location, ensuring effortless access
                      and seamless sharing for your job-seeking journey.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>

              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="AI Power" autoplay={true} animationData={fingerprintScanLottie} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      <strong>AI Powered</strong> - Premium users get tailored AI generated documents ranging from{' '}
                      <b>Resumes</b> or <b>Cover letters</b> to <b>Follow up</b> emails to make you stand out from the
                      crowd.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
            </BulletList>
          </Wrapper>
        </TableWrapper>

        <Spacer y={64} />

        <TableWrapper>
          <Wrapper>
            <H4>Organize and Manage Your Job Search in One Central Hub</H4>
            <Copy marginBottom={32}>
              Streamline your job application journey with JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, the ultimate
              solution for managing your job opportunities effortlessly.
            </Copy>
            <BulletList $bulletColor={Colors.Primary} $hasBullets={false}>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Organise'} src={organise1Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Embrace efficiency with JobsBoard<CopyColoredSpan>.io</CopyColoredSpan> - no more messy
                      spreadsheets or endless email hunts. Experience the joy of having all your applications neatly
                      organized in one central hub. Effortlessly manage job descriptions, documents, contacts, notes,
                      tasks, rates, locations, company information, and more with precision and ease.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Organise'} src={organise2Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      With our seamless and intuitive interface, tracking your application progress and interviews
                      becomes a breeze. No more guessing games or missed deadlines.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Organise'} src={organise3Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Effortlessly coordinate interviews, follow-ups, and milestones using the integrated calendar,
                      ensuring you maintain control and focus throughout your entire job search journey.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              {/* <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Organise'} src={organise4Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Take charge of your career journey confidently with JobsBoard
                      <CopyColoredSpan>.io</CopyColoredSpan>. Say hello to seamless organization, effortless tracking,
                      and endless opportunities!
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem> */}
            </BulletList>
            <Spacer y={32} />
            <PrimaryButtonLink
              to="login"
              inline={true}
              size="medium"
              iconRight={<ChevronRightIcon color={Colors.White} />}
            >
              Start Organizing Now
            </PrimaryButtonLink>
          </Wrapper>
          <Wrapper>
            <Placeholder
              icon={organizingImage}
              iconAlt="404"
              iconSize={Size.LARGE}
              title="Organize your Job Searches"
              isAnimated="right"
            />
          </Wrapper>
        </TableWrapper>

        <BackgroundColor backgroundColor={Colors.ContrastLightest} hasWave={false}>
          <TableWrapper>
            <Wrapper>
              <ResumesPreviewWrapper>
                <ResumesPreviewLeftWrapper>
                  <FunctionalIFrameComponent title="resume">
                    <ResumePreview state={stateBeethoven} hasNavigation={false} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewLeftWrapper>
                <ResumesPreviewCentralWrapper>
                  <FunctionalIFrameComponent title="resume">
                    <ResumePreview state={stateMozard} hasNavigation={false} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewCentralWrapper>
                <ResumesPreviewRightWrapper>
                  <FunctionalIFrameComponent title="resume">
                    <ResumePreview state={stateChopin} hasNavigation={false} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewRightWrapper>
              </ResumesPreviewWrapper>
            </Wrapper>
            <Wrapper>
              <H2>AI Resume Builder</H2>
              <H3>Craft Your Ultimate Resume with our AI assistant.</H3>
              <Copy>
                Elevate your chances with a standout resume, customized swiftly for each application, opening doors to a
                plethora of career opportunities.
              </Copy>
              <Spacer y={32} />
              <Copy styleLevel={2}>
                Our AI Resume Builder provides a seamless way to craft your resume with customizable templates and
                user-friendly features. Easily highlight your skills and experiences, then share your resume with
                recruiters, employers, and contacts in just one click. Say goodbye to formatting hassles - our platform
                streamlines the process so you can focus on advancing your career.
              </Copy>
              <Spacer y={32} />
              <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
                Sign Up for Free
              </PrimaryButtonLink>
            </Wrapper>
          </TableWrapper>
        </BackgroundColor>

        <Spacer y={64} />

        <BackgroundColor backgroundColor={Colors.White} hasWave={false}>
          <TableWrapper>
            <Wrapper>
              <H2>AI-Powered Cover Letter</H2>
              <H3>Craft Your Perfect Cover Letter with Ease</H3>
              <Copy>
                Impress employers with a tailored cover letter for every application, igniting your journey towards
                endless career opportunities.
              </Copy>
              <Spacer y={32} />
              <Copy styleLevel={2}>
                Our AI Cover Letter Builder simplifies the process of creating compelling cover letters. With intuitive
                features and customizable templates, crafting a personalized cover letter to showcase your skills and
                achievements becomes effortless. Once perfected, sharing your cover letter is as easy as a single click,
                allowing you to send it to recruiters, employers, and networking connections without hassle. Bid
                farewell to formatting headaches - our platform streamlines the entire process, enabling you to focus on
                propelling your career forward.
              </Copy>
              <Spacer y={32} />
              <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
                Create your Cover Letter
              </PrimaryButtonLink>
            </Wrapper>
            <Wrapper>
              <ResumesPreviewWrapper>
                <ResumesPreviewLeftWrapper>
                  <FunctionalIFrameComponent title="coverletter">
                    <CoverLetterPreview state={coverLetterBeethoven} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewLeftWrapper>
                <ResumesPreviewCentralWrapper>
                  <FunctionalIFrameComponent title="coverletter">
                    <CoverLetterPreview state={coverLetterStateMozardUpdated} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewCentralWrapper>
                <ResumesPreviewRightWrapper>
                  <FunctionalIFrameComponent title="coverletter">
                    <CoverLetterPreview state={coverLetterChopin} />
                  </FunctionalIFrameComponent>
                </ResumesPreviewRightWrapper>
              </ResumesPreviewWrapper>
            </Wrapper>
          </TableWrapper>
        </BackgroundColor>

        {/* <BackgroundColor backgroundColor="white" hasWave={false}>
          <CenteredWrapper>
            <H3>CREATE YOUR RESUME</H3>
            <H3>
              in 3 easy steps with <CopyColoredSpan>AI support</CopyColoredSpan>
            </H3>
          </CenteredWrapper>
          <CreateResumeSteps />
        </BackgroundColor> */}

        <BackgroundColor hasWave={true}>
          <CenteredWrapper>
            <H3>Constantly unleashing new features to supercharge your Job Search!</H3>
          </CenteredWrapper>
          <FeaturesGrid />
        </BackgroundColor>

        <TableWrapper>
          <Wrapper>
            <Placeholder
              icon={onlineConnection}
              iconAlt="404"
              iconSize={Size.LARGE}
              title="Online Collaboration"
              isAnimated="left"
            />
          </Wrapper>
          <Wrapper>
            <H4>Online Collaboration</H4>
            <Copy marginBottom={32}>
              JobsBoard<CopyColoredSpan>.io</CopyColoredSpan> allows you to collaborate with your recruiter on your job
              opportunities.
            </Copy>
            <BulletList $bulletColor={Colors.Primary} $hasBullets={false}>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Collaboration'} src={collaborate1Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Collaborate directly with your recruiter within the JobsBoard
                      <CopyColoredSpan>.io</CopyColoredSpan> platform to embrace efficiency.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Collaboration'} src={collaborate2Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Effortlessly share essential documents like resumes and cover letters with your recruiter,
                      streamlining your path to success.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Collaboration'} src={collaborate3Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Benefit from a streamlined and centralized collaboration experience, eliminating the need for
                      external communication tools.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'Collaboration'} src={collaborate4Icon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Gain valuable insights and guidance from your recruiter to enhance your chances of success.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
            </BulletList>
          </Wrapper>
        </TableWrapper>

        <BackgroundColor backgroundColor={Colors.PrimaryLightest} hasWave={true}>
          <FeaturesPanel title="Empowering Your Job Search Journey with AI">
            <Copy marginBottom={16}>
              Take charge of your Job Application journey with JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, your
              go-to solution for mastering the job search game.
            </Copy>
            <Copy marginBottom={16}>
              Our powerful suite of tools is designed to make your job search more efficient and effective.
            </Copy>
            <Copy marginBottom={32}>Ready to level up?</Copy>
            <Copy marginBottom={16}>Upgrade today!</Copy>
            <ButtonsWrapper
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <PrimaryButtonLink
                to="/checkout"
                inline={true}
                size="large"
                iconRight={<CrownIcon color={Colors.White} />}
              >
                Upgrade
              </PrimaryButtonLink>
              <SecondaryButton
                onClick={() => {
                  setIsModalVisible(true);
                }}
                inline={true}
              >
                Watch Video
              </SecondaryButton>
            </ButtonsWrapper>
          </FeaturesPanel>
        </BackgroundColor>

        <FeaturesSlider />

        <Spacer y={64} />

        <TableWrapper>
          <Wrapper>
            <Placeholder
              icon={artificialIntelligence}
              iconAlt="404"
              iconSize={Size.LARGE}
              title="AI Powered"
              isAnimated="right"
            />
          </Wrapper>
          <Wrapper>
            <H4>AI Powered</H4>
            <Copy marginBottom={32}>
              Artificial intelligence has swiftly transformed the realm of recruitment, despite being a recent addition.
              In a remarkably short span, it has revolutionized the field. Utilize our AI tools to enhance your Job
              Search, offering substantial benefits to job seekers like you.
            </Copy>
            <BulletList $bulletColor={Colors.Primary} $hasBullets={false}>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'AI'} src={AIIcon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Our AI assistant writer is designed to help you build your online search profile effectively. It
                      provides essential core skills and keywords to enhance your visibility to recruiters.
                      Additionally, it assists in crafting compelling cover letters, ensuring your job applications
                      stand out.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'AI Improve Resume'} src={CVIcon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Transform your resume with our AI Assistant, offering insightful suggestions and valuable
                      assistance throughout your job search journey. Elevate your prospects with a standout resume,
                      customized swiftly for every application, unlocking a world of career possibilities, all made
                      possible with the support of our AI assistant.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'AI Job Matching'} src={JobSearchIcon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Experience effortless efficiency with our AI importer, seamlessly integrating job applications
                      from URLs and automatically populating all the essential details on your Jobs Board. Say goodbye
                      to manual data entry and tedious form-filling - let our innovative tool handle the work for you,
                      ensuring a streamlined and hassle-free experience.
                    </Copy>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem>
              {/* <BulletListItem>
                <LineWrapper>
                  <IconWrapper>
                    <img alt={'AI'} src={InterviewIcon} />
                  </IconWrapper>
                  <ContentWrapper>
                    <Copy styleLevel={2}>
                      Our AI interview assistant will help you prepare for your interview by providing you with a list
                      of questions that you might be asked during the interview.
                    </Copy>
                    <span>
                      <Pill color={Colors.Contrast}>Coming Soon</Pill>
                    </span>
                  </ContentWrapper>
                </LineWrapper>
              </BulletListItem> */}
            </BulletList>
          </Wrapper>
        </TableWrapper>
      </LayoutFullWidthMargin>

      <Suspense fallback={<SmallLoader />}>
        <ErrorBoundary>
          <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
            <VideoPlayer url="https://youtu.be/1yvGXc_bP-A"></VideoPlayer>
          </Modal>
        </ErrorBoundary>
      </Suspense>
    </>
  );
};

export default Home;

const Wrapper = styled.div``;

const LineWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap: wrap;

  strong {
    color: ${Colors.PrimaryDarkest};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  flex: 0 0 64px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 300px;
  gap: 16px;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${media.from_tablet`
    grid-template-columns: 1fr 1fr;
    gap: 64px;
    > :nth-of-type(1) {
      order: 1;
    }
    > :nth-of-type(2) {
      order: 2;
    }
    > :nth-of-type(3) {
      order: 3;
    }
    > :nth-of-type(4) {
      order: 4;
    }
    > :nth-of-type(5) {
      order: 6;
    }
    > :nth-of-type(6) {
      order: 5;
    }
    > :nth-of-type(7) {
      order: 7;
    }
    > :nth-of-type(8) {
      order: 8;
    }
  `}
`;

const CenteredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
