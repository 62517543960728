import styled from 'styled-components';

import { PrimaryButtonLink } from '../../atoms/Button/Buttons';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { HeadingStyle1, HeadingStyle2 } from '../../../styles/fonts';

const BACKGROUND_URL = '/images/background-macbook-center-dark.jpg';
// const BACKGROUND_URL = '/images/background-macbook-left.jpg';

export const HeroWithBackgroundImage = () => {
  return (
    <Container $backgroundUrl={BACKGROUND_URL}>
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <Heading>Smart Dashboard</Heading>
            <SlantedBackground>Empowering Your Job Search Journey with AI</SlantedBackground>
            <PrimaryButtonLink to="login" inline>
              Login / SignUp
            </PrimaryButtonLink>
          </LeftColumn>
          <RightColumn></RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

const Container = styled.div<{ $backgroundUrl: string }>`
  position: relative;
  margin-top: -64px;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.$backgroundUrl});
  padding-block: 96px 32px;

  ${media.from_tablet`
    padding-block: 128px 64px;
  `}
`;

const OpacityOverlay = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.8;
  background-color: ${Colors.Contrast};
`;

const HeroContainer = styled.div`
  position: relative;
  max-width: 100%;
  z-index: 10;
  padding-inline: var(--right-left-global-padding);
`;

const TwoColumn = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding-block: 64px;
  border: 1px solid ${Colors.Primary};
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`;

const RightColumn = styled.div``;

const Heading = styled.div`
  ${HeadingStyle1}
`;

const SlantedBackground = styled.span`
  ${HeadingStyle2}
  position: relative;
  padding: 32px;
  font-size: 2rem;
  margin-inline: 32px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #f0f0f0;
    transform: skewX(-12deg);
    z-index: -1;
  }
`;
